.home {
    background-color: var(--background-color);
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    gap: 5px;
    user-select: none;
    position: relative;
}
