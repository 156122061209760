.navigation {
    position: -webkit-sticky; /* for browser compatibility */
    position: sticky;
    top: 10px;
    top: 0;
}

.navigation-items {
    background-color: var(--background-color);
    color: var(--primary-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    user-select: none;
}

.navigation-items > p {
    font-size: 1.2rem;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    padding: 2px 8px;
    padding-top: 6px;
}

.navigation-items > p:nth-child(odd) {
    border: 2px solid var(--background-color);
    transition: 0.4s ease-in-out;
}

.navigation-items > p:nth-child(odd):hover {
    cursor: pointer;
    color: var(--background-color);
    border: 2px solid var(--primary-color);
    background-color: var(--primary-color);
}

@media (max-width: 900px) {

    .navigation-items {
        gap: 3px;
    }
    
    .navigation-items > p {
        font-size: 1rem;
        font-weight: 700;
        padding: 2px 6px;
        padding-top: 4px;
    }    

}

@media (max-width: 450px) {

    .navigation-items {
        gap: 3px;
    }
    
    .navigation-items > p {
        font-size: 0.8rem;
        font-weight: 600;
        padding: 1px 2px;
        padding-top: 2px;
    }    

}