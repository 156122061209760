button {
    font-family: 'Josefin Sans', sans-serif;
    border: 1.5px solid var(--primary-color);
    background-color: rgba(0, 0, 0, 0);
    padding: 3px 10px;
    padding-top: 7px;
    transition: 0.3s ease-in-out;
    border-radius: 1px;
    font-weight: 600;
    font-size: 0.9rem;
}

.button:hover {
    cursor: pointer;
    color: var(--background-color);
    background-color: var(--primary-color);
}

.blue {
    border: 1.5px solid #3a86ff;
    color: #3a86ff;
}
.blue:hover {
    border: 1.5px solid #3a86ff;
    background-color: #3a86ff
}


.purple {
    border: 1.5px solid #6f2dbd;
    color: #6f2dbd;
}
.purple:hover {
    border: 1.5px solid #6f2dbd;
    background-color: #6f2dbd;
}


.orange {
    border: 1.5px solid #fb5607;
    color: #fb5607;
}
.orange:hover {
    border: 1.5px solid #fb5607;
    background-color: #fb5607;
}

.grey {
    border: 1.5px solid #555b6e;
    color: #555b6e;
}
.grey:hover {
    border: 1.5px solid #555b6e;
    background-color: #555b6e;
}

.white {
    border: 1.5px solid var(--background-color);
    color: var(--background-color);
}
.white:hover {
    border: 1.5px solid var(--background-color);
    background-color: var(--background-color);
    color: var(--primary-color);
}

@media only screen and (max-width: 950px) {
    button {
        padding: 2px 8px;
        padding-top: 5px;
        font-weight: 500;
        font-size: 0.75rem;
    }
}