@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:700');
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');


.home-animation__heading {
    display: flex;
    flex-direction: column;
    font-family: 'Limelight', cursive;
    gap: 10px;
    transition: 0.3s;
}

.heading__line {
    display: flex;
    flex-direction: row;
    height: fit-content;
    padding: 0px;
    margin: 0px;
}

.heading__line h1 {
    padding: 0px;
    margin: 0px 0;
    font-size: 5rem;
    line-height: 5rem;
}

.space {
    gap: 25px;
}

.line__space {
    background-color: var(--primary-color);
    width: 100%;
    margin: 10px 0;
    margin-bottom: 18px;
    position: relative;
    flex: 1;
}

.flip-top, 
.flip-bottom {
    overflow: hidden;
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5px;
}


.flip-top > div,
.flip-bottom > div {
    position: relative;
    animation: show 8s linear infinite;
}

@keyframes show {
    0% { bottom: -10rem; }
    2% { bottom: -5rem; }
    33% { bottom: -5rem; }
    35% { bottom: -0rem; }
    66% { bottom: -0rem; }
    68% { bottom: 5rem; }
    99.9% { bottom: 5rem; }
    100% { bottom: -15rem; }
}

@media (max-width: 900px) {
    .heading__line h1 {
        padding: 0px;
        margin: 0px 0;
        font-size: 3rem;
        line-height: 3rem;
    }
    
    .space {
        gap: 12.5px;
    }
    
    .line__space {
        background-color: var(--primary-color);
        width: 100%;
        margin: 5px 0;
        margin-bottom: 9px;
        flex: 1;
    }
    
    .flip-top, 
    .flip-bottom {
        height: 3rem;
        padding: 0 2.5px;
    }
    
    .flip-top > div,
    .flip-bottom > div {
        position: relative;
        animation: show 8s linear infinite;
    }
    
    @keyframes show {
        0% { bottom: -6rem; }
        2% { bottom: -3rem; }
        33% { bottom: -3rem; }
        35% { bottom: -0rem; }
        66% { bottom: -0rem; }
        68% { bottom: 3rem; }
        99.9% { bottom: 3rem; }
        100% { bottom: -9rem; }
    }
}

@media (max-width: 450px) {
    .heading__line h1 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    
    .space {
        gap: 6px;
    }
    
    .line__space {
        margin: 2.5px 0;
        margin-bottom: 4.5px;
        flex: 1;
    }
    
    .flip-top, 
    .flip-bottom {
        height: 1.5rem;
        padding: 0 2px;
    }
    
    @keyframes show {
        0% { bottom: -3rem; }
        2% { bottom: -1.5rem; }
        33% { bottom: -1.5rem; }
        35% { bottom: -0rem; }
        66% { bottom: -0rem; }
        68% { bottom: 1.5rem; }
        99.9% { bottom: 1.5rem; }
        100% { bottom: -4.5rem; }
    }
}