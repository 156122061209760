.scroll-to-projects {
    position: absolute;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: 0.3s ease-in-out;
    color: var(--primary-color-light)
}

.scroll-to-projects:hover {
    color: var(--primary-color);
    cursor: pointer;
    animation: shake 0.5s ease-in-out 3;
}


.scroll-to-projects > p {
    font-size: 0.7rem;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 900;
    line-height: 1.5rem;
    margin: 0;
}

.scroll-to-projects > p:first-of-type {
    padding-bottom: 0px;
}

.scroll-to-projects > p:last-of-type {
    transform: rotate(90deg);
}

@keyframes shake {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(3px);
    }
    75% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0);
    }
  }
