.contact {
    min-height: 100vh;
    width: 100vw;
    background-color: var(--background-color-secondary);
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: relative;
    background-color: var(--primary-color);
    padding: 20px;
    padding-top: 40px;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: fit-content;
    margin-top: 30px;
    margin-bottom: 20px;
}

.contact__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.contact__details > h3 {
    color: var(--background-color);
    font-size: 2.3rem;
    font-weight: 900;
    width: fit-content;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0px;
    font-family: 'Limelight', cursive;
    text-align: center;
}

.contact__details > p {
    font-family: 'Josefin Sans', sans-serif;
    color: var(--background-color);
    text-align: center;
    font-size: 1.2rem;
    line-height: 2.4rem;
}

.form__inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    min-width: 300px;
    max-width: 420px;
    width: 400px;
}

label {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    border-radius: 3px;
}


.form__input {
    border: none;
    font-family: 'Josefin Sans', sans-serif;
    color: var(--background-color);
    font-size: 1rem;
    border: 2px solid var(--background-color);
    padding: 12px;
    padding-top: 15px;
    border-radius: 1px;
    background-color: var(--primary-color);
}


.message > textarea {
    height: 25vh;
    border-radius: 1px;
    padding-top: 15px;
    background-color: var(--primary-color);
    color: var(--background-color);
}

input:focus,
textarea:focus {
    outline: none;
    /* border: 2px solid var(--primary-color); */
}

form button {
    display: inline-block;
    width: 100%;
    background-color: var(--background-color);
    color: var(--primary-color);
    border: none;
    font-size: 0.9rem;
    padding: 12px 30px;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    font-family: 'Josefin Sans', sans-serif;
    border: 2px solid var(--background-color);
    border-radius: 1px;
    padding-top: 15px;
}

form > button:hover {
    cursor: pointer;
    background-color: var(--primary-color);
    border: 2px solid var(--background-color);
    color: var(--background-color);
}

.contact > img {
    width: 300px;
    height: 300px;
    border-radius: 30%;
}

input::placeholder,
textarea::placeholder {
    color: var(--background-color)
}

.contact__links {
    display: flex;
    flex-direction: row;
    gap: 20px
}


@media only screen and (max-width: 1200px) {
    /* .contact__details > h3 {
        font-size: 2rem;
    }
    .contact__details > p {
        font-size: 1rem;
        line-height: 1.8rem;
    } */
    form {
        gap: 20px;
        margin-top: 15px;
    }
    .form__inputs {
        gap: 15px;
        width: 380px;
    }
}


@media only screen and (max-width: 950px) {

    /* .contact__details > h3 {
        font-size: 1.5rem;
    }
    .contact__details > p {
        font-size: 0.9rem;
        line-height: 1.6rem;
    } */
    form {
        gap: 10px;
        margin-top: 15px;
    }
    .form__inputs {
        gap: 15px;
        width: 280px;
    }
}