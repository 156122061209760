@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Swanky+and+Moo+Moo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Loop:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mali&display=swap');


.about-me {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    font-family: 'Josefin Sans', sans-serif;
    position: relative;
    padding: 20px;
    padding-top: 40px;
}

.main__heading {
    font-family: 'Limelight', cursive;
    color: var(--primary-color);
    font-size: 2.3rem;
    font-weight: 900;
    transition: 0.3s ease-in-out;
    width: fit-content;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 40px;
    
}

.about-me__main {
    max-width: 870px;
    padding: 30px;
    color: var(--primary-color)
}

.about-me__text {
    text-align: left;
    font-size: 1.2rem;
    line-height: 2.4rem;
    color: var(--primary-color);
}

div > .about__icons {
    margin-top: 45px;
}

@media only screen and (max-width: 1200px) {
    .about-me__main h3 {
        font-size: 2rem;
        font-weight: 900;
        margin-bottom: 25px;
    }

    .about-me__main p {
        font-size: 1rem;
        line-height: 2rem;
    }

    div > .about__icons {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 950px) {

    .about-me {
        padding: 15px;
        text-align: center;
    }

    .about-me__main h3 {
        font-size: 1.5rem;
        font-weight: 900;
        margin-bottom: 25px;
    }

    .about-me__main p {
        font-size: 0.9rem;
        line-height: 1.8rem;
    }

    div > .about__icons {
        margin-top: 30px;
    }
}