@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:700');

* {
  box-sizing: border-box;
}

html, body {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Josefin Sans', sans-serif;
  font-family: 'Limelight', cursive;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  position: relative;
  overflow-y: scroll;

}

:root {


  /* --primary-color: #6a1aff;
  --secondary-color:#060917;
  --tertiary-color: #ffffff */
  
  --primary-color: #212A3E;
  --primary-color: #7371fc;
  --primary-color-light: #aba9fd;


  --primary-color: #212A3E;
  --primary-color-light: #83858C;
  --background-color: #f8f9fa;
  --background-color-secondary: #ffffff;

  /* --background-color: #6a1aff;
  --background-color-secondary:#060917;
  --primary-color-light: #ffffff;
  --primary-color: #f8f9fa; */



}

.hidden-form {
  display: none;
}