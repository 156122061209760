.project-page__full {
    background-color: var(--background-color);
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.project-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
    position: relative;
    max-width: 1000px;
    padding: 20px;
    padding-top: 40px;
}

.project__info {
    font-family: 'Josefin Sans', sans-serif;
    display: flex;
    flex-direction: column;
    max-width: 500px;
}

.project__info h3 {
    color: var(--primary-color);
    font-size: 2.3rem;
    font-weight: 900;
    transition: 0.3s ease-in-out;
    width: fit-content;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 20px;
    font-family: 'Limelight', cursive;
}

.info__description {
    font-size: 1.2rem;
    line-height: 2.4rem;
    color: var(--primary-color);
}

.info__technologies {
    font-size: 1rem;
    line-height: 2rem;
    color: var(--primary-color-light)
}

.info__links {
    display: flex;
    flex-direction: row;
    gap: 20px;
    color: var(--primary-color);
    margin-top: 30px;
    transition: 0.3s;
}

.info__links > *:hover {
    cursor: pointer;
}

.info__links > svg {
    transform: scale(1);
}

.project__image {
    overflow: hidden;
}

.project__image > img {
    width: 500px;
    height: 400px;
    object-fit: cover;
    position: relative;
}

.project__image > img:hover {
    cursor: pointer;
}

.reverse {
    flex-direction: row-reverse;
}

@media (max-width: 1200px) {
    
    .project-page {
        gap: 40px;
        max-width: 1000px;
    }
    
    .project__info {
        max-width: 400px;
    }
    
    .project__info h3 {
        font-size: 2rem;
        font-weight: 900;
        margin-bottom: 20px;
        gap: 10px
    }
    
    .info__description {
        font-size: 1rem;
        line-height: 2rem;
    }
    
    .info__technologies {
        font-size: 0.9rem;
        line-height: 1.2rem;
    }
    
    .info__links {
        gap: 15px;
        margin-top: 20px;
    }
    
    .project__image > img {
        width: 400px;
        height: 320px;
    }

}

@media (max-width: 950px) {
    
    .project-page__full {
        padding: 15px
    }

    .project-page {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        max-width: 500px;
    }

    .project__info h3 {
        font-size: 1.5rem;
        font-weight: 900;
        margin-bottom: 20px;
        text-align: left;
    }
    
    .info__description {
        font-size: 0.9rem;
        line-height: 1.8rem;
        margin-bottom: 0px;
        text-align: left;
    }
    
    .info__technologies {
        font-size: 0.9rem;
        line-height: 1.8rem;
    }
    
    .info__links {
        gap: 15px;
        margin-top: 15px;
    }
    
    .project__image > img {
        width: 320px;
        height: 280px;
    }

}